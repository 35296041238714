import React from "react"
import { useSpring } from "react-spring"
import { Link } from "gatsby"

import BreakableTitle from "../BreakableTitle"
import PopUp from "./PopUp/PopUp"
// import PopUp from "./PopUpScholarship/PopUp"

import {
  Wrapper,
  SolutionsList,
  SolutionsListItem,
  SolutionTitle,
} from "./styles"

const HomeDisplay = ({ content }) => {
  const props = useSpring({ opacity: 1, from: { opacity: 0 } })

  return (
    <Wrapper style={props}>
      <SolutionsList>
        <PopUp></PopUp>
        {content.map(({ _id, title, slug }) => (
          <SolutionsListItem key={_id}>
            <SolutionTitle>
              <Link to={`/products/${slug.current}`}>
                <BreakableTitle wrap={true}>{title}</BreakableTitle>
              </Link>
            </SolutionTitle>
          </SolutionsListItem>
        ))}
        <SolutionTitle contrast>
          <Link to={`/contact`}>
            <BreakableTitle wrap={true}>Claim your spot!</BreakableTitle>
          </Link>
        </SolutionTitle>
      </SolutionsList>
    </Wrapper>
  )
}

export default HomeDisplay
