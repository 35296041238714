import React, { useEffect } from "react"
import { useTheme } from "../../hooks/useTheme"
import HomeDisplay from "./HomeDisplay"

const HomeContainer = ({ content }) => {
  const { theme, setTheme } = useTheme()

  useEffect(() => {
    setTheme("blue")
  }, [setTheme])

  return <HomeDisplay content={content} theme={theme} />
}

export default HomeContainer
