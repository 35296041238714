import styled from "@emotion/styled"
import { animated } from "react-spring"

import { colors, fixedValues, fonts } from "../../styles/variables.styles"
import { wrapper } from "../../styles/shared.styles"
import { mq } from "../../styles/helpers.styles"

export const Wrapper = styled(animated.section)`
  background: ${colors.primary.blue};
  transition: background 500ms ease-in-out;
`

export const SolutionsList = styled.div`
  ${wrapper}
  list-style: none;
  position: relative;
  z-index: 1;
  width: 100%;
  min-height: 100vh;
  padding-top: ${`calc(${fixedValues.headerHeightMobile} + 5vh)`};

  ${mq.tablet} {
    padding-top: ${`calc(${fixedValues.headerHeight} + 5vh)`};
  }

  ${mq.desktop} {
    padding-top: ${`calc(${fixedValues.headerHeight} + 5vh)`};
  }

  ${mq.desktop__l} {
    padding-top: ${`calc(${fixedValues.largeHeaderHeight} + 5vh)`};
  }
`

export const SolutionsListItem = styled.div``

export const SolutionTitle = styled.h2`
  font-family: ${fonts.title};
  font-size: 4.2rem;
  line-height: 1.1em;
  padding-bottom: 0.5em;
  a {
    color: ${({ contrast }) => (contrast ? "#FFF" : "#000")};
  }
  ${mq.tablet} {
    font-size: 6.2rem;
  }
  ${mq.desktop} {
    font-size: 7.2rem;
    line-height: 1em;
  }
  ${mq.desktop__l} {
    font-size: 9.2em;
    padding-bottom: 0.75em;
  }
`
