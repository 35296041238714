import React from "react"
import styled from "@emotion/styled"
import { mq } from "../styles/helpers.styles"
const MobileTitle = styled.div`
  ${mq.tablet} {
    display: none;
    visibility: hidden;
  }
`
const DesktopTitle = styled.div`
  display: none;
  visibility: hidden;
  span {
    display: block;
  }
  ${mq.tablet} {
    display: block;
    visibility: visible;
  }
`

const BreakableTitle = ({ children, wrap }) => {
  const splitTitle = children.split("_newline_")

  return wrap ? (
    <div>{splitTitle.join(" ")}</div>
  ) : (
    <>
      <MobileTitle>{splitTitle.join(" ")}</MobileTitle>
      <DesktopTitle>
        {splitTitle.map((part, index) => (
          <span key={index}>{part.trim()}</span>
        ))}
      </DesktopTitle>
    </>
  )
}

export default BreakableTitle
