import React from "react"
import { graphql } from "gatsby"

import SEO from "../components/seo"
import HomeContainer from "../components/Home"

export const getSolutions = graphql`
  query MyQuery {
    solutions: allSanitySolution(sort: { fields: order, order: ASC }) {
      content: nodes {
        _id
        title
        slug {
          current
        }
      }
    }
  }
`
const Home = ({ data: { solutions } }) => (
  <>
    <SEO title="Home" />
    <HomeContainer content={solutions.content} />
  </>
)

export default Home
