import React, { useState } from 'react';
import './popup.css';

const PopUp = () => {
    const [show, setShow] = useState(true);
    return (
        <div className={show?'show popup':'popup'}>
            <div className="popup-overlay" 
                    onClick={() => {
                        setShow(false);
                    }}
                >

            </div>
            <div id="popup" className={'popUp-container'}>
                
                <div className="close-container" onClick={() => { 
                    setShow(false);
                }}>
                </div>
                <div className="button-container">
                    <a className="get-started-button" href="https://book.seagulls.com/" target="_blank">{'LEARN MORE>>'}</a>
                </div>
            </div>
        </div>
    )
};

export default PopUp;
